<template>
  <div class="grid grid-cols-3 gap-2">
    <router-link
      class="text-center p-2"
      v-for="item in items.filter((x) =>
        searchFilter ? x.title.toLowerCase().includes(searchFilter.toLowerCase()) : true
      )"
      :key="item.title"
      :to="item.path"
    >
      <div
        class="bg-tertiary mx-auto bg-opacity-50 rounded-full h-16 w-16 sm:w-20 sm:h-20 text-center p-2 sm:p-4 duration-300"
      >
        <!-- <v-icon name="MdBookmarkSharp" /> -->
        <v-icon :icon="item.icon" class="text-primary text-5xl" />
        <!-- <img v-bind:src="item.icon" alt="placeholder" class="mx-auto" /> -->
      </div>
      <span>{{ item.title }}</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "FeatureItemsComp",
    props: {
      searchFilter: {
        type: String
      }
    },
    data() {
      return {
        items: [
          // {
          //   title: "Slip Gaji",
          //   icon: "ph:invoice",
          //   description: "Generate slip gaji karyawan.",
          //   path: "/dashboard/payroll"
          // },
          {
            title: "Mulai Absen",
            icon: "ph:clock-duotone",
            description: "Mulai Absensi",
            path: "/dashboard/attendance/new"
          },
          {
            title: "Mulai Laporan Harian",
            icon: "ph:clipboard-duotone",
            description: "Mulai Laporan Harian",
            path: "/dashboard/report/daily/new"
          },
          {
            title: "Overtime",
            icon: "ph:calendar-dots-duotone",
            description: "Overtime",
            path: "/dashboard/overtime"
          },
          {
            title: "Ringkasan Laporan Harian",
            icon: "ph:user-list-duotone",
            description: "Absensi karyawan.",
            path: "/dashboard/report/daily"
          },
          {
            title: "Ringkasan Kehadiran",
            icon: "ph:calendar-check-duotone",
            description: "Absensi karyawan.",
            path: "/dashboard/attendance"
          }
          // {
          //   title: "Izin",
          //   icon: "ph:user-list",
          //   description: "Izin karyawan.",
          //   path: "/dashboard/"
          // }
        ]
      };
    }
  };
</script>
