import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/pages/HomePage.vue";
import About from "@/pages/AboutPage.vue";

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import FeatLayout from "@/layouts/FeatureLayout.vue";
import Dashboard from "@/pages/DashboardPage.vue";
import BlankLayout from "@/layouts/BlankLayout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        component: Dashboard
      },
      {
        path: "about",
        component: About
      }
    ]
  },
  {
    path: "/dashboard",
    component: BlankLayout,
    children: [
      {
        path: "attendance",
        component: FeatLayout,
        props: {
          title: "Attendance"
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/attendance/AttendancePage.vue")
          },

          {
            path: "new",
            component: () => import("@/pages/attendance/NewAttendancePage.vue")
          }
        ]
      },
      {
        path: "overtime",
        component: FeatLayout,
        props: {
          title: "Report"
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/reports/Overtime/OvertimePage.vue")
          },
          {
            path: "new",
            component: () => import("@/pages/reports/Overtime/NewOvertimePage.vue")
          }
        ]
      },
      {
        path: "report",
        component: FeatLayout,
        props: {
          title: "Report"
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/reports/ReportPage.vue")
          },
          {
            path: "daily",
            component: () => import("@/pages/reports/Daily/DailyReportPage.vue")
          },
          {
            path: "patrol",
            component: () => import("@/pages/reports/Patrol/PatrolReportPage.vue")
          },
          {
            path: "cleaning",
            component: () => import("@/pages/reports/Cleaning/CleaningReportPage.vue")
          },
          {
            path: "patrol/new",
            component: () => import("@/pages/reports/Patrol/NewPatrolReportPage.vue")
          },
          {
            path: "daily/new",
            component: () => import("@/pages/reports/Daily/NewDailyReportPage.vue")
          },
          {
            path: "cleaning/new",
            component: () => import("@/pages/reports/Cleaning/NewCleaningReportPage.vue")
          }
        ]
      },
      {
        path: "payroll",
        component: FeatLayout,
        props: {
          title: "Payroll"
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/payroll/PayrollPage.vue")
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
