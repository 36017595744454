<template>
  <div class="w-full absolute flex justify-center items-center h-full px-4">
    <div class="max-w-sm">
      <form action="" class="p-4 rounded-lg mx-auto" @submit="login">
        <img src="/images/jwm_transparent.png" alt="" class="w-24 mb-8 mx-auto" />
        <h1 class="text-xl my-4">Login</h1>
        <div>
          <input
            type="username"
            name="username"
            v-model="loginForm.username"
            id="email"
            placeholder="NIK / ID"
            class="border border-b-2 rounded bg-primary bg-opacity-5 border-primary px-2 py-2 w-full"
          />
        </div>
        <div class="flex mt-4">
          <input
            :type="peekPassword ? 'text' : 'password'"
            name="password"
            v-model="loginForm.password"
            id="password"
            placeholder="Password"
            class="border border-b-2 rounded-l bg-primary bg-opacity-5 border-primary px-2 py-2 w-full"
          />
          <button
            type="button"
            @click="peekPassword = !peekPassword"
            class="flex-shrink-0 right-0 top-0 px-3 rounded-r bg-primary"
          >
            <v-icon
              :icon="peekPassword ? 'material-symbols:visibility-off' : 'material-symbols:visibility'"
              class="text-2xl text-white"
            />
          </button>
        </div>
        <div class="text-right">
          <button class="bg-primary font-bold text-white p-2 rounded min-w-32 mt-4">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from "@/plugins/axios";

  export default {
    name: "HomePage",
    data() {
      return {
        loginForm: {
          username: "",
          password: ""
        },
        peekPassword: false
      };
    },
    methods: {
      login(e) {
        e.preventDefault();
        // console.log(axios.defaults.headers["Authorization"]);
        axios
          .post("/login", this.loginForm)
          .then((res) => {
            axios.defaults.headers["Authorization"] = res.data.token;
            localStorage.setItem("token", res.data.token);
            this.$router.push("/dashboard");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };
</script>
