import axios from "axios";

const instance = axios.create({
  //"https://demo-api.kyuiki.com",
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: localStorage.getItem("token")
  }
});

export default instance;

export function fileUpload(blob) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("image", blob);
    instance
      .post("/attachment", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
