// src/states/local.js
import { reactive, watch } from "vue";
import { useOnline } from "@vueuse/core";
import instance, { fileUpload } from "@/plugins/axios";

// Constants
const STORAGE_KEYS = {
  QUEUE: "uploadQueue",
  FILES: "fileData"
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 5000; // 5 seconds

// Initialize storage
const queueData = JSON.parse(
  localStorage.getItem(STORAGE_KEYS.QUEUE) || '{"attendance":[],"report":[],"overtime":[],"uploads":[]}'
);
// const filesData = JSON.parse(localStorage.getItem(STORAGE_KEYS.FILES) || "{}");

// Convert stored base64 files back to blobs
const convertBase64ToBlob = (base64Data) => {
  const [metadata, base64] = base64Data.split(",");
  const mimeType = metadata.split(":")[1].split(";")[0];
  const binary = atob(base64);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }
  return new Blob([array], { type: mimeType });
};

const convertBlobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// State
export const online = useOnline();
export const uploadQueue = reactive({
  attendance: queueData.attendance || [],
  report: queueData.report || [],
  overtime: queueData.overtime || [],
  uploads: queueData.uploads || []
});

// Queue management
const processQueue = async () => {
  if (!online.value || !uploadQueue.uploads.length) return;

  const item = uploadQueue.uploads[0];
  if (item.retries >= MAX_RETRIES) {
    uploadQueue.uploads.shift();
    return;
  }

  try {
    // Convert stored base64 back to blobs for file uploads
    const processedImages = await Promise.all(
      item.data.images.map(async (base64Data) => {
        const blob = convertBase64ToBlob(base64Data);
        return await fileUpload(blob);
      })
    );

    // Update the request data with processed image URLs
    const requestData = {
      ...item.data,
      images: processedImages
    };

    // Make the API call
    await instance.post(item.endpoint, requestData);

    // Remove successfully processed item
    uploadQueue.uploads.shift();

    // Remove related data from other queues
    if (item.type === "attendance") {
      uploadQueue.attendance = uploadQueue.attendance.filter((a) => a.id !== item.id);
    } else if (item.type === "report") {
      uploadQueue.report = uploadQueue.report.filter((r) => r.id !== item.id);
    } else if (item.type === "overtime") {
      uploadQueue.overtime = uploadQueue.overtime.filter((o) => o.id !== item.id);
    }
  } catch (error) {
    console.error("Upload failed:", error);
    item.retries = (item.retries || 0) + 1;
    if (item.retries < MAX_RETRIES) {
      setTimeout(processQueue, RETRY_DELAY);
    }
  }
};

// Watchers
watch(
  () => online.value,
  async (isOnline) => {
    if (isOnline) {
      await processQueue();
    }
  }
);
await processQueue();

watch(
  () => uploadQueue,
  (newValue) => {
    localStorage.setItem(STORAGE_KEYS.QUEUE, JSON.stringify(newValue));
  },
  { deep: true }
);

// Queue management functions
const addToQueue = async (type, endpoint, data) => {
  const id = Date.now().toString();

  // Convert blobs to base64 for storage
  if (data.images) {
    data.images = await Promise.all(data.images.map((blob) => convertBlobToBase64(blob)));
  }

  uploadQueue.uploads.push({
    id,
    type,
    endpoint,
    data,
    retries: 0,
    timestamp: Date.now()
  });

  if (online.value) {
    processQueue();
  }

  return id;
};

// Export functions
export const queueManager = {
  async addAttendance(data) {
    const id = await addToQueue("attendance", "/attendance/assign", data);
    uploadQueue.attendance.push({ ...data, id });
  },

  async addReport(data) {
    const id = await addToQueue("report", "/report/create", data);
    uploadQueue.report.push({ ...data, id });
  },

  async addOvertime(data) {
    const id = await addToQueue("overtime", "/overtime/submit", data);
    uploadQueue.overtime.push({ ...data, id });
  }
};
