<template>
  <div>
    <div>
      <NavigationBarComp />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import NavigationBarComp from "@/components/NavigationBarComp.vue";

  export default {
    name: "DashboardLayout",
    components: {
      NavigationBarComp
    }
  };
</script>
