<template>
  <div class="pb-32">
    <HeroComp>
      <div class="mt-4">
        <UserDetailsComp />
      </div>
    </HeroComp>

    <div class="flex justify-end gap-2 mb-4 mx-2">
      <input
        type="text"
        class="rounded-full bg-primary bg-opacity-10 px-4 py-2"
        placeholder="Cari..."
        v-model="filterFeature"
      />
      <button class="p-2 bg-primary bg-opacity-10 rounded-full">
        <v-icon icon="material-symbols:search" class="text-2xl" />
      </button>
    </div>
    <FeatureItemsComp :search-filter="filterFeature" />
    <!-- <div class="flex p-2 w-auto">
      <div>
        <carousel>
          <slide class="w-full">
            <p>test aja</p>
          </slide>
          <slide>
            <p>test 2</p>
          </slide>
        </carousel>
      </div>
    </div> -->
    <FloatingNavbarMenu />
    <FromDeveloper />
  </div>
</template>

<script>
  import FeatureItemsComp from "@/components/FeatureItemsComp.vue";
  import HeroComp from "@/components/HeroComp.vue";
  import FloatingNavbarMenu from "@/components/FloatingNavbarMenu.vue";
  import UserDetailsComp from "@/components/UserDetailsComp.vue";
  import FromDeveloper from "@/components/FromDeveloper.vue";
  // import { Carousel, Slide } from "vue-carousel";

  export default {
    name: "DashboardPage",
    data() {
      return {
        filterFeature: ""
      };
    },
    components: {
      FeatureItemsComp,
      HeroComp,
      FloatingNavbarMenu,
      UserDetailsComp,
      FromDeveloper
      // Carousel,
      // Slide
    }
  };
</script>
