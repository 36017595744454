import { reactive } from "vue";

export const location = reactive({
  latitude: null,
  longitude: null,
  accuracy: null,
  error: null
});

export const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        location.latitude = position.coords?.latitude;
        location.longitude = position.coords?.longitude;
        location.accuracy = position.coords?.accuracy;
        location.error = null;
      },
      (error) => {
        location.error = error.message;
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }
};

export const watchLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.watchPosition(
      (position) => {
        console.log("watching location", position);
        location.latitude = position.coords?.latitude;
        location.longitude = position.coords?.longitude;
        location.accuracy = position.coords?.accuracy;
        location.error = null;
      },
      (error) => {
        location.error = error.message;
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }
};
