<template>
  <div class="flex gap-4 items-center">
    <div class="bg-secondary rounded px-2 text-black py-2 w-full relative overflow-hidden">
      <div class="">
        <p class="text-lg">{{ user.fullName }}</p>
        <p>{{ user.email || "Email Not Specified" }}</p>
        <p>{{ user.phone || "Phone Not Specified" }}</p>
        <v-icon
          icon="material-symbols:person-4-rounded"
          style="font-size: 8.5em"
          class="absolute -top-4 -rotate-12 -right-4 text-white opacity-50"
        />
      </div>
    </div>
    <div class="flex-shrink-0 w-24 h-24 rounded-2xl overflow-hidden border-2 border-secondary">
      <img src="/kyuiki.png" class="h-full w-full" />
    </div>
  </div>
</template>
<script>
  import $axios from "@/plugins/axios";
  export default {
    name: "UserDetailsComp",
    data() {
      return {
        user: {
          name: "John Doe",
          email: "example@example.com",
          phone: "1234567890"
        }
      };
    },
    mounted() {
      $axios
        .get("/user/@me")
        .then((res) => {
          this.user = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
</script>
